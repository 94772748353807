import header from "images/header.jpeg";
import "components/Header.css";

function Header() {
  return (
    <header>
      <h1 className="header-text">The Green Owl</h1>
      <h2 className="header-text">Software Development</h2>
      <div className="header-container container">
        <img className="header-image" src={header} alt="logo" />
        <div className="transition" />
      </div>
    </header>
  );
}

export default Header;
