import { faMeteor } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "components/Offering.css";

const offerList = [
  "$0 down",
  "Flat monthly rate based on complexity",
  "Fully customizable",
  "Web and mobile solutions",
  "Unlimited edit requests",
  "Authentication and commerce",
];

function Offering() {
  return (
    <div className="container">
      <h2>What we offer:</h2>
      {offerList.map((offer) => (
        <div className="offer" key={offer}>
          <FontAwesomeIcon icon={faMeteor} className="fa-1x icon" />
          {offer}
        </div>
      ))}
    </div>
  );
}

export default Offering;
