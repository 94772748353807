import Header from "components/Header";
import Body from "components/Body";
import Contact from "components/Contact";
import Examples from "components/Examples";
import Offering from "components/Offering";

function Home() {
  return (
    <>
      <Header />
      <Body />
      <Examples />
      <Offering />
      <Contact />
    </>
  );
}

export default Home;
