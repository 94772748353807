import "components/Body.css";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const contactArr = [
  {
    logo: faEnvelope,
    text: "Email",
    link: "mailTo:contact@green-owl-technology.com",
  },
  {
    logo: faLinkedin,
    text: "LinkedIn",
    link: "https://www.linkedin.com/in/jason-anderson-15b9a156",
  },
  {
    logo: faFile,
    text: "Resume",
    link: "https://jason-ryan-anderson.com",
  },
];

function Body() {
  return (
    <div className="contact">
      {contactArr.map((contact) => (
        <a
          href={contact.link}
          rel="noopener noreferrer"
          target="_blank"
          key={contact.text}
        >
          <FontAwesomeIcon icon={contact.logo} className="fa-2x icon" />
          <span>{contact.text}</span>
        </a>
      ))}
    </div>
  );
}

export default Body;
