import Example1kc from "images/Example1-KCycling.png";
import Example2goc from "images/Example2-GOC.png";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "components/Examples.css";

const images = [
  { img: Example1kc, href: "https://kcyclingcoaching.com/" },
  { img: Example2goc, href: "https://green-owl-compliance.com/" },
];

function Examples() {
  return (
    <div className="container">
      <h2>Our work:</h2>
      <Fade>
        {images.map((image, index) => (
          <div className="image-centering-tool" key={index}>
            <div className="images">
              <img src={image.img} />
              <h3>
                <a href={image.href} rel="noopener noreferrer" target="_blank">
                  Link
                </a>
              </h3>
            </div>
          </div>
        ))}
      </Fade>
    </div>
  );
}

export default Examples;
