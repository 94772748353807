import "components/Contact.css";
import { submittedPathname } from "utils/consts";
import ContactForm from "components/ContactForm";
import { useLocation, useNavigate } from "react-router-dom";

function Contact() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const shouldAllowContact = search !== submittedPathname;

  return (
    <div className="container">
      {shouldAllowContact ? (
        <ContactForm />
      ) : (
        <>
          <h2>Thanks for your message!</h2>
          <button className="submit-button" onClick={() => navigate("")}>
            Submit Again!
          </button>
        </>
      )}
    </div>
  );
}

export default Contact;
