import { SyntheticEvent, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "utils/firebase";
import { submittedPathname } from "utils/consts";
import { useNavigate } from "react-router-dom";

const addMessage = httpsCallable(functions, "addMessage");

function ValidateEmail(value: string): boolean {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
}

function ContactForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [shouldShowValidation, setShowValidationMessage] = useState(false);

  const isValidSubject = !!subject;
  const subjectInputClass =
    shouldShowValidation && !isValidSubject ? "invalid-input" : "";

  const isValidMessage = !!message;
  const messageInputClass =
    shouldShowValidation && !isValidMessage ? "invalid-input" : "";

  const isValidEmail = !email || ValidateEmail(email);
  const emailInputClass =
    shouldShowValidation && !isValidEmail ? "invalid-input" : "";

  const isValidSubmission = isValidSubject && isValidMessage && isValidEmail;
  const buttonText = loading ? "Wait" : "Submit";

  const resetData = () => {
    setEmail("");
    setSubject("");
    setMessage("");
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (isValidSubmission) {
      setShowValidationMessage(false);
      setLoading(true);
      addMessage({ email, subject, message })
        .then(resetData)
        .catch(console.error)
        .finally(() => {
          navigate(submittedPathname);
          setLoading(false);
        });
    } else {
      setShowValidationMessage(true);
    }
  };

  return (
    <>
      <h2>Contact</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          className={emailInputClass}
          disabled={loading}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="Email (optional)"
          type="text"
          value={email}
        />
        <input
          className={subjectInputClass}
          disabled={loading}
          onChange={(event) => setSubject(event.target.value)}
          placeholder="Subject"
          type="text"
          value={subject}
        />
        <textarea
          className={messageInputClass}
          disabled={loading}
          onChange={(event) => setMessage(event.target.value)}
          placeholder="Message"
          rows={3}
          value={message}
        />
        <button
          className="submit-button"
          disabled={loading}
          onClick={handleSubmit}
          type="submit"
        >
          {buttonText}
        </button>
      </form>
    </>
  );
}

export default ContactForm;
